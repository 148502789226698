export const ENV = 'production';

let API_BASE_URL = ''

if (ENV == "staging") {
  API_BASE_URL =
    "https://gqlip0wnzg.execute-api.us-west-2.amazonaws.com/staging/";
}
if (ENV == "production") {
  API_BASE_URL =
    "https://gqlip0wnzg.execute-api.us-west-2.amazonaws.com/production/";
}

export const BUCKET_NAME = (ENV == 'staging') ? 'all3d-dev' : 'all3d';
//Cloudfront distribution id
export const CLOUDFRONT_ID = (ENV == 'staging') ? 'EWXIJSKOEGJHL':'E1PCYBAKSUG4Z2';

export const BASE_URL =  (ENV == 'staging') ? 'https://all3d-dev.s3.amazonaws.com/test_files/' : 'https://d48f7equ64qjl.cloudfront.net/test_files/';
export const COMPANY_LOGO = (ENV == 'staging') ? 'https://all3d-dev.s3.amazonaws.com/static_files/company/' : 'https://d48f7equ64qjl.cloudfront.net/static_files/company/';
export const ROOM_PANORAMA_ASSET =  (ENV == 'staging') ? "https://all3d-dev.s3.amazonaws.com/room_assets/panorama/" : "https://d48f7equ64qjl.cloudfront.net/room_assets/panorama/";
export const MATERIAL_FILE_URL = (ENV == 'staging') ?  'https://all3d-dev.s3.amazonaws.com/materials/' : 'https://d48f7equ64qjl.cloudfront.net/materials/';
export const SCANS_BASE_URL = "https://all3d-reconstruction.s3.amazonaws.com/temp_results/";
export const SCENE_BASE_URL =  (ENV == 'staging') ? 'https://all3d-dev.s3.amazonaws.com/scene_renders/' : 'https://d48f7equ64qjl.cloudfront.net/scene_renders/';
export const COMPANY_MODEL = (ENV == 'staging') ? 'https://all3d-dev.s3.amazonaws.com/static_files/featured_models/' : 'https://d48f7equ64qjl.cloudfront.net/static_files/featured_models/';
//get categories
export const ADMIN_CATEGORY_BATCH = 'https://xnhluveva9.execute-api.us-west-2.amazonaws.com/staging/all3d-category-get-batch';
export const ADMIN_CATEGORY_GET_BATCH = API_BASE_URL + 'admin-category-get-batch';
export const GET_CATEGORY_AND_PRICE = 'https://0za35gi4ef.execute-api.us-west-2.amazonaws.com/' + ENV;

//get materials
export const ADMIN_MATERIAL_BATCH = 'https://cvs5huxxi0.execute-api.us-west-2.amazonaws.com/staging/all3d-material-get-batch'

export const COMPANY_GET_BATCH = 'https://2pveenfj3h.execute-api.us-west-2.amazonaws.com/' + ENV;
export const GET_STORE_ITEMS = API_BASE_URL + 'get-store-items';
export const FETCH_PRODUCT = 'https://2l2rojkmv4.execute-api.us-west-2.amazonaws.com/'+ENV;
export const FETCH_ROOM = 'https://nvckc8moz4.execute-api.us-west-2.amazonaws.com/'+ENV;
export const PRODUCT_MATERIAL_GET_BATCH = 'https://reksl0cik2.execute-api.us-west-2.amazonaws.com/' + ENV;
export const COMPANY_GET = 'https://ks8s8ia9pf.execute-api.us-west-2.amazonaws.com/' + ENV;
export const HIGH_POINT_CONTACT_IS = 'https://pdzpy5zvd4.execute-api.us-west-2.amazonaws.com/' + ENV;

//Template Actions API
export const TEMPLATE_ACTIONS = " https://jc4qy03fcc.execute-api.us-west-2.amazonaws.com/" + ENV;
export const TEMPLATE_BASE_URL = (ENV == 'staging') ? 'https://all3d-dev.s3.amazonaws.com/template_renders/' : 'https://d48f7equ64qjl.cloudfront.net/template_renders/';

//Customer Management APIs
export const CUSTOMER_GET_BATCH = API_BASE_URL + 'customer-get-batch';

//Lifestyles Feed API
export const GET_LIFESTYLES_FEED = API_BASE_URL + "get-lifestyles-feed";