import NavBar from "../landingPage/header/navbar";
import React from "react";
import Footer from "../landingPage/footer";
import TextConstants from "../../TextConstants";
import MetaTagContents from "../../MetaTagContents";
import { Helmet } from "react-helmet";
import "./index.scss";

const Amazon = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{MetaTagContents.INTEGRATIONS.title}</title>
        <link rel="canonical" href="https://all3d.ai/integrations" />
      </Helmet>
      <React.Fragment>
        <NavBar />
        <div className="container-fluid amazon-integration-container">
          <div className="integrations">
            <WebVersion />
            <MobileVersion />
          </div>
        </div>
        <Footer />
      </React.Fragment>
    </div>
  );
};

const { title, detailsText, buttonTitleText, buttonText } =
  TextConstants.AMAZON_INTEGRATION;

const WebVersion = (props) => {
  return (
    <div className="d-none d-lg-block">
      <div
        className="col-lg-12 col-12"
        id="textSection"
        style={{ margin: "auto", padding: 0 }}
      >
        <h1 className="font lora f-32 w-400 ws-6 black-2b justify-in-center">
          {title}
        </h1>
      </div>
      <div className="justify-in-center">
        <div className="amazon-integration-details">
          <img
            className="ml-12 amazon-integration-model"
            src="/simple_bedding.png"
            alt="#"
          />
          <span className="font graphik-regular f-20 w-400 black-2b mt-16 ml-12">
            {detailsText}
          </span>
        </div>
      </div>
      <div className="amazon-integration-button">
        <p className="font graphik-regular f-16 w-400 black-2b mt-16">
          {buttonTitleText}
        </p>
        <button
          onClick={() => {
            window.location.href = "https://app.all3d.ai/integrations";
          }}
          type="button"
          className="btn connect-btn font graphik-regular mt-16 f-16 w-500 white"
        >
          {buttonText}
          <img className="ml-12" src="/landingPage/right-arrow.png" alt="#" />
        </button>
      </div>
    </div>
  );
};

const MobileVersion = (props) => {
  return (
    <div className="d-lg-none">
      <div
        className="col-lg-12 col-12"
        id="textSection"
        style={{ margin: "auto", padding: 0 }}
      >
        <h1 className="font lora f-32 w-400 ws-6 black-2b justify-in-center text-center">
          {title}
        </h1>
      </div>
      <div className="justify-in-center">
        <div className="amazon-integration-details--mobile">
          <img
            className="ml-12"
            style={{ width: "100%" }}
            src="/simple_bedding.png"
            alt="#"
          />
          <p className="font graphik-regular f-20 w-400 black-2b mt-16 text-center">
            {detailsText}
          </p>
        </div>
      </div>
      <div className="amazon-integration-button">
        <p className="font graphik-regular f-16 w-400 black-2b mt-16 text-center">
          {buttonTitleText}
        </p>
        <button
          onClick={() => {
            window.location.href = "https://app.all3d.ai/integrations";
          }}
          type="button"
          className="btn connect-btn font graphik-regular mt-16 f-16 w-500 white"
        >
          {buttonText}
          <img className="ml-12" src="/landingPage/right-arrow.png" alt="#" />
        </button>
      </div>
    </div>
  );
};

export default Amazon;
